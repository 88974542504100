export const fields = [
    { key: 'index', label: "No", _style:'min-width:30px'},
    { key: 'select', label: "", _style:'min-width:30px' },
    { key: 'kecamatan', label: "Kecamatan", _style:'min-width:100px' },
    { key: 'desa', label: "Kelurahan", _style:'min-width:100px' },
    { key: 'nama_poktan', label: "Nama Poktan", _style:'min-width:100px' },
    { key: 'nama_penggarap', label: "Nama Penggarap", _style:'min-width:100px' },
    { key: 'nama_pemilik', label: "Nama Pemilik Lahan", _style:'min-width:100px' },
    { key: 'luas_lahan', label: "Luas Lahan (Ha/tumbak)", _style:'min-width:100px' },
    { key: 'alamat', label: "Alamat Lahan Garapan", _style:'min-width:100px' },
    { key: 'no_telp', label: "No. Telp", _style:'min-width:100px' },
    { key: 'longitude', label: "Longitude", _style:'min-width:100px' },
    { key: 'latitude', label: "Latitude", _style:'min-width:100px' },
    { key: 'created_at', label: "Tanggal Dibuat", _style:'min-width:100px' },
    { key: 'updated_at', label: "Tanggal Diubah", _style:'min-width:100px' },
    { key: "action", label: 'Aksi', _style: 'min-width:75px'}
]
